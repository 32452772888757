import { Container, Flex, Text } from '@chakra-ui/react'
import Head from 'next/head'

import { useTranslation } from '~/lib/i18n'

const FOOTER_HEIGHT = '188px'

const NotFound = () => {
  const { t } = useTranslation('layout')

  return (
    <>
      <Head>
        <title>{t('notFound.pageTitle')}</title>
      </Head>
      <Container maxW="container.xl">
        <Flex minH={`calc(100vh - ${FOOTER_HEIGHT})`} direction="column" justify="center" align="center">
          <Text fontSize={20} fontWeight={600} pb={10}>
            {t('notFound.404')}
          </Text>
          <Text>{t('notFound.body')}</Text>
        </Flex>
      </Container>
    </>
  )
}

export default NotFound
